import React from 'react';

import './TitleContact.scss';
let classNames = require('classnames');

export const TitleContact = ({ ...props }) => {
    return (
        <h2 className={classNames([props.removeicon ? 'title-deco-remove' : 'title-deco', props.class])}>{props.title}</h2>
    );
};

TitleContact.defaultProps = {
    title: 'Investisseurs'
};

export default TitleContact;
