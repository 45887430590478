import React from 'react'
import TitleSection from '../../Molecules/TitleSection/TitleSection'
import './BlockExpertise.scss'
import ShapeBackground from '../../Molecules/ShapeBackground/ShapeBackground'
var classNames = require('classnames');

const BlockExpertise = ({ ExpTitle, ExpText, ExpTextlist, TextColor }) => {
    const color = (TextColor === 'white' || TextColor === 'catskill_white' || TextColor === 'catskill_dark') ? 'bleu' : 'white';
    return (
        <div className='blockexpertise'>

            <div className="wrapper_page_xs">
                <TitleSection
                    title={ExpTitle}
                    h2color={color}
                />
                <div className='flex_content'>
                    <div className={classNames('txt-wrapper', 'color_' + color)} dangerouslySetInnerHTML={{ __html: ExpText }} />
                </div>

                <div className='bullet_wrapper'>
                    {
                        ExpTextlist.map((list, index) => {
                            return (
                                <div className={classNames('list-wrap', 'color_' + color)} dangerouslySetInnerHTML={{ __html: list.processed }} key={index}>
                                </div>
                            )
                        })

                    }
                </div>

            </div>

        </div>
    )
}

export default BlockExpertise