import React from 'react';

import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import './BlockTextSimple.scss';

const BlockTextSimple = ({ title, type, text, hasTwoCol, subtitle }) => {
  return (
    <>
      <div className="title-exp">
        <TitleSection title={title} type={type} />
      </div>
      <div className={hasTwoCol ? 'block-content-two' : 'block-content-simple'}>
        <div
          className="txt-wrapper-exp"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />

        {subtitle && hasTwoCol && (
          <div
            className="txt-wrapper-exp"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      </div>
    </>
  );
};

export default BlockTextSimple;
