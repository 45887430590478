import React from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n/src';
import BlockExpertise from '../../../../components/BlockExpertise/BlockExpertise';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import Carousel from '../../../../components/Carousel/Carousel';
import ContactFormExpertise from '../../../../components/ContactFormExpertise/ContactFormExpertise';
import BlockTextSimple from '../../../../components/ContentType/BlockTextSimple/BlockTextSimple';
import TextVisuelCTATwoCol from '../../../../components/ContentType/TextVisuelCTATwoCol/TextVisuelCTATwoCol';
import VisuelComponent from '../../../../components/ContentType/VisuelComponent/VisuelComponent';
import PageBannerReverse from '../../../../components/PageBannerReverse/PageBannerReverse';
import Seo from '../../../../components/Seo/Seo';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import CardSection from '../../../../components/Solution/CardSection/CardSection';
import useMedias from '../../../../hooks/useMedias';
import Layout from '../../../../Layout';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import { TitleContact } from '../../../../Molecules/TitleContact/TitleContact';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import { getReOrderedCarouselSlides, getSectionBG, getTextColor, removeHtmlTag, getBreadCrumb, } from '../../../../Utils/Utils';

import './styles.scss';

let classNames = require('classnames');

const ExpertisePage = ({ data, pageContext }) => {
  const intl = useIntl();
  const { getImage, processUrl } = useMedias();

  const slider_settings = {
    className: 'center_dots',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1,
    speed: 300,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: true
        },
      },
    ],
  };

  let pagesContactExpertise = [];

  for (let i = 1; i <= 12; i++) {
    pagesContactExpertise.push({
      id: i,
      label: intl.formatMessage({
        id: 'contactexpertise.form.field.type.list.opt' + i,
      }),
    });
  }

  const pageData = data?.expertisePageData?.edges[0]?.node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  const imagesArray = data?.allImages?.edges;
  const locale = pageData.langcode;
  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  return (
    <Layout>
      <Seo
        title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
        description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'page_expertise')}>
        <PageBannerReverse
          visuel={getImage(
            imagesArray,
            pageData?.relationships?.field_image?.drupal_internal__mid
          )}
          alt={pageData?.relationships?.field_image?.field_media_image?.alt || ''}
        >
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                grandparentPage: {
                  title: breadCrumb?.grandParent?.name,
                  url: breadCrumb?.grandParent?.link,
                },
                gparent: {
                  title: breadCrumb?.gGrandParent?.name,
                  url: breadCrumb?.gGrandParent?.link,
                },
                parentPage: {
                  title: breadCrumb?.parent?.name,
                  url: breadCrumb?.parent?.link,
                },
                currentPage: {
                  title: breadCrumb?.current?.name,
                  url: breadCrumb?.current?.link,
                },
                locale: pageData.langcode,
              }}
            />
            <TitlePage
              color="color_white"
              title={
                pageData?.field_text_2
                  ? pageData?.field_text_2?.processed
                  : pageData.title
              }
              description={pageData?.body?.processed}
            />
          </div>
        </PageBannerReverse>
        <div className="bg_white">
          {pageData.relationships.field_blocs.map((block, i_page_blocks) => {
            switch (block.__typename) {
              case 'block_content__block_texte_simple':
                return (
                  <section className="block-exp-simple" key={i_page_blocks}>
                    <div className="wrapper_page">
                      <BlockTextSimple
                        title={block?.field_title.processed}
                        type={'no_line'}
                        text={block?.body?.processed}
                        hasTwoCol={true}
                        subtitle={block?.field_subtitle?.processed}
                      />
                    </div>
                  </section>
                );

              case 'block_content__block_image':
                return (
                  <section className="block-exp-image-simple" key={i_page_blocks}>
                    {
                      block?.relationships?.field_image &&
                      <VisuelComponent
                        extremeLeft={true}
                        visuel={getImage(
                          imagesArray,
                          block?.relationships?.field_image?.drupal_internal__mid
                        )}
                        alt={block?.relationships?.field_image?.field_media_image?.alt || ''}
                        size={'half_page'}
                      />
                    }

                  </section>
                );

              case 'block_content__basic2':
                return (
                  <section className={`section_content block-exp-basic2${i_page_blocks} ${!pageData.relationships?.field_blocs[i_page_blocks + 1]?.field_position_bordure_haut ? 'small_infinite' : ''}`} key={i_page_blocks}>
                    <ShapeBackground
                      top={
                        !block.field_position_bordure_haut ?
                          null :
                          block.field_position_bordure_haut === 'gauche' ?
                            'left' :
                            'right'
                      }
                      bottom={
                        !block.field_position_bordure_bas ?
                          null :
                          block.field_position_bordure_bas === 'gauche' ?
                            'left' :
                            'right'
                      }
                      bottomInfinite={block.field_infinite_border}
                      color={getSectionBG(block.field_backgroud_color)}
                    >
                      <div className="wrapper_page">
                        <TextVisuelCTATwoCol
                          extremeLeft={
                            block?.imageAlignment === 'left' ? true : false
                          }
                          extremeRight={
                            block?.field_alignment === 'right' ||
                              block?.field_alignment === null
                              ? true
                              : false
                          }
                          orderInverse={
                            block?.field_alignment === 'right' ||
                              block?.field_alignment === null
                              ? true
                              : false
                          }
                          text={block.body.processed}
                          visuelSize="half"
                          title={{
                            titleSize: 'h2',
                            titleText: block?.field_title.processed,
                          }}
                          visuel={block?.relationships?.field_image ? getImage(
                            imagesArray,
                            block?.relationships?.field_image
                              ?.drupal_internal__mid
                          ) : null}
                          alt={block?.relationships?.field_image?.field_media_image?.alt || ''}
                          listiconArrow={true}
                          textColor={getTextColor(getSectionBG(block.field_backgroud_color))}

                        />
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_innovation':
                let slides = [];
                block.relationships?.field_slides?.forEach((slideType) => {
                  slides.push({
                    image: getImage(
                      imagesArray,
                      slideType?.relationships?.field_image
                        ?.drupal_internal__mid
                    ),
                    alt: slideType?.relationships?.field_image?.field_media_image?.alt || '',
                    info: {
                      text:
                        slideType.title || slideType?.field_title?.processed,
                      link:
                        slideType.field_link?.url ? slideType.field_link?.url : '',
                      link_label: block.field_link_title,
                    },
                  });
                });
                return (
                  <section className="block-exp-slider section_content" key={i_page_blocks}>
                    <ShapeBackground
                      top={
                        !block.field_position_bordure_haut ?
                          null :
                          block.field_position_bordure_haut === 'gauche' ?
                            'left' :
                            'right'
                      }
                      bottom={
                        !block.field_position_bordure_bas ?
                          null :
                          block.field_position_bordure_bas === 'gauche' ?
                            'left' :
                            'right'
                      }
                      bottomInfinite={block.field_infinite_border}
                      color={getSectionBG(block.field_backgroud_color)}
                    >
                      <div className="wrapper_page">
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={block.field_title?.processed}
                            type="arrow"
                          />
                        </div>

                        {slides.length > 0 && (
                          <div
                            className={classNames(
                              'wrapper_page_xs',
                              'only_desktop'
                            )}
                          >
                            <Carousel
                              slides={getReOrderedCarouselSlides(slides)}
                              contentName={removeHtmlTag(block.field_title?.processed)}
                              pageName={pageData.title}
                              pageType='groupe'
                              zone={removeHtmlTag(block.field_title?.processed)}
                            />
                          </div>
                        )}
                        <div className={classNames('only_mobile_tablet')}>
                          <SliderComponent settings={slider_settings}>
                            {slides?.map((slide, j) => (
                              <div key={j}>
                                <div className="only_mobile_tablet">
                                  <img src={processUrl(slide.image?.image_style_uri?.gatsby_profile_thumbnail)} alt={slide.alt || ''} />
                                </div>
                              </div>
                            ))}
                          </SliderComponent>
                        </div>
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_expertise':
                return (
                  <section className="section_content block-exp-expertise" key={i_page_blocks}>
                    <ShapeBackground
                      top={
                        !block.field_position_bordure_haut ?
                          null :
                          block.field_position_bordure_haut === 'gauche' ?
                            'left' :
                            'right'
                      }
                      bottom={
                        !block.field_position_bordure_bas ?
                          null :
                          block.field_position_bordure_bas === 'gauche' ?
                            'left' :
                            'right'
                      }
                      bottomInfinite={block.field_infinite_border}
                      color={getSectionBG(block.field_backgroud_color)}
                    >
                      <BlockExpertise
                        ExpTitle={block?.field_title?.processed}
                        ExpText={block?.body?.processed}
                        ExpTextlist={block?.field_bloc_text_1}
                        TextColor={getSectionBG(block.field_backgroud_color)}
                      />
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_solutions':
                return (
                  <section className="section section_content block-exp-solution" key={i_page_blocks}>
                    <ShapeBackground
                      top={
                        !block.field_position_bordure_haut ?
                          null :
                          block.field_position_bordure_haut === 'gauche' ?
                            'left' :
                            'right'
                      }
                      bottom={
                        !block.field_position_bordure_bas ?
                          null :
                          block.field_position_bordure_bas === 'gauche' ?
                            'left' :
                            'right'
                      }
                      bottomInfinite={block.field_infinite_border}
                      color={getSectionBG(block.field_backgroud_color)}
                    >
                      <div className="wrapper_page_xs  title-solution">
                        <TitleSection
                          title={block.field_title?.processed}
                          type="arrow"
                        />
                      </div>

                      <CardSection
                        imagesArray={imagesArray}
                        section={block}
                        counter={1}
                        listType="solutions"
                        page_name='mobilites_douces'
                      />
                    </ShapeBackground>
                  </section>
                );
            }
          })}

          <div className="expertise_form block-exp-form">
            <div className="wrapper_page bg-white">
              <div className="frm-wrapper">
                <div className="title-center">
                  <TitleContact
                    removeicon={true}
                    title={intl.formatMessage({
                      id: 'contactexpertise.form.title',
                    })}
                  ></TitleContact>
                </div>

                <div className="col">
                  <div className="contact_form_component_container">
                    <ContactFormExpertise
                      formName="contact_expertise"
                      langCode={locale}
                      page_name={pageData.path.alias?.replace('/', '')?.replaceAll('-', '_')}
                      title={pageData.title}
                      pagesContact={pagesContactExpertise}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ExpertiseTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    expertisePageData: allNodeExpertise(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          path {
            alias
          }
          title
          field_text_2 {
            processed
          }
          langcode
          relationships {
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_expertise {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_bloc_text_1 {
                    processed
                  }
                  field_position_bordure_bas
                  field_position_bordure_haut
                  field_infinite_border
                  field_backgroud_color
                }

                ... on block_content__basic2 {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }

                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                  field_alignment
                  field_backgroud_color
                  field_position_bordure_bas
                  field_position_bordure_haut
                  field_infinite_border
                }

                ... on block_content__block_texte {
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                }

                ... on block_content__block_texte_simple {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                }

                ... on block_content__block_image {
                  id
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }

                ... on block_content__block_innovation {
                  id
                  relationships {
                    field_slides {
                      field_title {
                        processed
                      }
                      field_link {
                        url
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                  field_title {
                    processed
                  }
                  field_backgroud_color
                  field_position_bordure_bas
                  field_position_bordure_haut
                  field_infinite_border
                }

                ... on block_content__block_solutions {
                  id
                  field_title {
                    processed
                  }
                  relationships {
                    field_blocs {
                      field_bloc_text_1 {
                        processed
                      }
                      field_link {
                        url
                        title
                      }
                      field_text {
                        processed
                      }
                      field_subtitle
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                  field_backgroud_color
                  field_position_bordure_bas
                  field_position_bordure_haut
                  field_infinite_border
                }
              }
            }
          }

          body {
            processed
          }
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel        
              }
            }
          }
        }
      }
    }
    
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default ExpertisePage;
