import React, { useState } from 'react';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { HorizontalYellowLineShape, MaqsueBannerCardProduct } from '../../Atoms/Icons/Icons';
import { Button } from '../../Molecules/Button/Button';
import { removeHtmlTag } from '../../Utils/Utils';

import './styles.scss';
function ProductCards({ cards, page_name }) {
  const [newCard, setNewCard] = useState(cards);
  const intl = useIntl();
  let classNames = require('classnames');

  const onClickSolutionCardBtn = (e, title) => {

    // let _paq = (window._paq = window._paq || []);
    //   _paq.push([
    //     'trackEvent',
    //     'expert_page',
    //     'Solution button click',
    //     'click',
    //     'ok',
    //   ]);

    window.dataLayer = window.dataLayer || [];
    console.log({
      'event': 'clic_cartes',
      'page_name': page_name,
      'activity': 'route',
      'button_content': 'lire_la_suite',
      'page_type': 'groupe',
      'zone': 'nos_solutions',
      'content_name': title
    });

    window.dataLayer.push({
      'event': 'clic_cartes',
      'page_name': page_name,
      'activity': 'route',
      'button_content': 'lire_la_suite',
      'page_type': 'groupe',
      'zone': 'nos_solutions',
      'content_name': title
    })

    console.log(window.dataLayer)

  }
  return (
    <div className="products_wrapper" >
      {newCard.map((card, i) => (
        <div className="card_wrapper" key={i}>
          <div className="img_wrapper">
            <div className="card_vector">
              <div className="inner_img_wrapper">
                <img src={card.img} alt={card.alt} />
                <MaqsueBannerCardProduct /*clipPath={'_' + i + '_' + sec}*/ className={classNames(
                  'shap_blue_banner'
                )} />
              </div>
              <div className="card_upper">
                <div className="card_txt">
                  {
                    card?.title_formatted ?
                      (
                        <h1 dangerouslySetInnerHTML={{ __html: card?.title_formatted.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '') }} />
                      ) :
                      (
                        <h1>{card?.title}</h1>
                      )
                  }

                  <div className="icon">
                    <HorizontalYellowLineShape className={classNames(
                      'tirer'
                    )} />
                  </div>
                  <h2>{card?.subTile}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="lower_wrapper">
            <div className="card_lower">
              <div className="card_lower_inner">
                <div className="wrapper_txt" dangerouslySetInnerHTML={{ __html: card.paragraph }} />

                <div className="btn_wrapper">
                  <Button
                    primary={true} label={intl.formatMessage({ id: 'solutions.readMore' })}
                    link={card.link?.url}
                    target='_blank'
                    onClickEvent={(e) => onClickSolutionCardBtn(e, card?.title_formatted ? removeHtmlTag(card?.title_formatted)?.replace('\n', '') : card?.title)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductCards;
