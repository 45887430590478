import React, { useRef } from 'react';

import { CloseIcon } from '../../Atoms/Icons/Icons';
import { uniqueID } from '../../Utils/Utils';

import './input.scss';
let classNames = require('classnames');

const Input = ({ reset, change, validator, field, optional }) => {
  const inputRef = useRef();
  const onReset = (e) => {
    e.preventDefault();
    reset();
    inputRef.current.focus();
  };
  const uniqueID_val = uniqueID('input_');

  let params = !optional ? 'required' : '';
  if (field.type === 'email') {
    params += '|email';
  }
  if (field.type === 'number') {
    params += '|numeric';
    params += '|min:5|max:5';
  }
  const msg_error = validator?.message(field.id, field.value, params);

  return (
    <div
      className={classNames('input', field.customClass, {
        error_exist: msg_error,
      })}
    >
      <label htmlFor={uniqueID_val}>{field.label}</label>
      <div className="input_wrap">
        <input
          ref={inputRef}
          id={uniqueID_val}
          className={classNames({ value_exist: field.value.length > 0 })}
          type={field.type === 'number' ? 'text' : field.type}
          aria-required={optional ? 'false' : 'true'}
          name={field.name}
          value={field.value}
          placeholder={field.placeholder}
          onChange={(e) => change(e.currentTarget.value)}
          {...(msg_error && { 'aria-invalid': 'true' })}
          {...(msg_error && {
            'aria-describedby': 'error_field_' + field.name,
          })}
        />
        {msg_error && (
          <div className="error" id={`error_field_${field.name}`}>
            {msg_error}
          </div>
        )}
        {field.value.length > 0 && (
          <button
            className="clear"
            onClick={onReset}
            tabIndex="0"
            area-label={field.textClear + ' ' + field.label.replace('*', '')}
          >
            <CloseIcon />
          </button>
        )}
      </div>
    </div>
  );
};

Input.defaultProps = {
  label: 'Sujet de votre email',
  name: 'email',
  value: undefined,
  placeholder: 'Le sujet de votre eMail',
  type: 'alpha' /* https://www.npmjs.com/package/simple-react-validator#rules */,
  optional: false,
};

export default Input;
