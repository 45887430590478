import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import ProductCards from '../../ProductCards/ProductCards';
import useMedias from '../../../hooks/useMedias';

import './CardSection.scss';

let classNames = require('classnames');

const CardSection = ({
  imagesArray,
  section,
  customClass,
  page_name
}) => {
  const { getImage } = useMedias();
  let cardsArr = [];

  let singleCardDetails = {};
  section?.relationships?.field_blocs?.forEach(cardDetails => {

    singleCardDetails = {};
    singleCardDetails.title = cardDetails?.title;
    singleCardDetails.title_formatted = cardDetails?.field_text?.processed;
    singleCardDetails.subTile = cardDetails?.field_subtitle;
    singleCardDetails.paragraph = cardDetails?.field_bloc_text_1?.processed;
    singleCardDetails.btn = cardDetails?.field_cta;
    singleCardDetails.link = cardDetails?.field_link;
    singleCardDetails.img = getImage(imagesArray, cardDetails.relationships?.field_image?.drupal_internal__mid)?.uri?.url;
    singleCardDetails.alt = cardDetails.relationships?.field_image?.field_media_image?.alt || '';
    cardsArr.push(singleCardDetails);

  })

  return (

    <div
      className={classNames(
        'cards_section',
        'product_page_component',
        'section_' + section?.relationships?.field_category?.drupal_internal__tid,
        customClass ? customClass : ''
      )}
    >
      <div className="wrapper_page">
        <ProductCards
          cards={cardsArr}
          page_name={page_name}
        />
      </div>
    </div>

  );
};

CardSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  quote: PropTypes.object,
};

export default CardSection;
